import Network from "~helpers/Network";

async function createIntent(projectId, chatbot, body) {
  return await Network.request(`project/${projectId}/chatbot/${chatbot}/intent/`, {
    method: "POST",
    data: body,
  });
}

async function getIntentDetails(projectId, chatbot, intentId) {
  return await Network.request(`project/${projectId}/chatbot/${chatbot}/intent/${intentId}/`);
}

async function deleteIntents(projectId, chatbot, id) {
  return await Network.request(`project/${projectId}/chatbot/${chatbot}/intent/${id}/`, {
    method: "DELETE",
  });
}

async function updateIntent(projectId, chatbot, body) {
  return await Network.request(`/project/${projectId}/chatbot/${chatbot}/intent/${body.id}/`, {
    method: "PUT",
    data: body,
  });
}

async function uploadIntents(project, bot_id, file) {
  let form_data = new FormData();
  form_data.append("file", file, file.name);
  console.log(file);
  console.log(file.name);
  return await Network.request(`project/${project}/chatbot/${bot_id}/import-export`, {
    method: "POST",
    data: form_data,
  });
}

async function downloadIntents(project, bot_id) {
  return await Network.request(`project/${project}/chatbot/${bot_id}/import-export`, {
    responseType: "blob",
  });
}

export { createIntent, getIntentDetails, deleteIntents, uploadIntents, updateIntent, downloadIntents };
