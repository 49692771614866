export const ON_BROADCAST_MESSAGE = "@SOCKET/LIVECHAT/ON_BROADCAST_MESSAGE";

export const SET_AGENT_INFO = "@SOCKET/LIVECHAT/SET_AGENT_INFO";
export const SET_ONLINE_AGENTS = "@SOCKET/LIVECHAT/SET_ONLINE_AGENTS";
export const SET_CALLCENTER_SETTINGS = "@SOCKET/LIVECHAT/SET_CALLCENTER_SETTINGS";
export const SET_SESSION_CALLCENTER_SETTINGS = "@SOCKET/LIVECHAT/SET_SESSION_CALLCENTER_SETTINGS";
export const SET_LLM_SETTINGS = "@SOCKET/LIVECHAT/SET_LLM_SETTINGS";

export const WS_CONNECT = "@SOCKET/LIVECHAT/WS_CONNECT";
export const WS_DISCONNECT = "@SOCKET/LIVECHAT/WS_DISCONNECT";
export const WS_RECONNECT = "@SOCKET/LIVECHAT/WS_RECONNECT";
export const WS_PING = "@SOCKET/LIVECHAT/WS_PING";
export const WS_CONNECT_SUCCESS = "@SOCKET/LIVECHAT/WS_CONNECT_SUCCESS";
export const WS_CONNECT_FAIL = "@SOCKET/LIVECHAT/WS_CONNECT_FAIL";
export const WS_LOGIN = "@SOCKET/LIVECHAT/WS_LOGIN";
export const WS_LOGIN_SUCCESS = "@SOCKET/LIVECHAT/WS_LOGIN_SUCCESS";
export const WS_LOGIN_FAIL = "@SOCKET/LIVECHAT/WS_LOGIN_FAIL";
export const WS_SEND_MESSAGE = "@SOCKET/LIVECHAT/SEND_MESSAGE";
export const WS_SEND_MESSAGE_LISTEN_SESSION = "@SOCKET/LIVECHAT/SEND_MESSAGE_LISTEN_SESSION";

export const ON_MESSAGE = "@SOCKET/LIVECHAT/ON_MESSAGE";
export const SET_SESSION_STATUS = "@SOCKET/LIVECHAT/SET_SESSION_STATUS";
export const SET_SELECTED_SESSION = "@SOCKET/LIVECHAT/SET_SELECTED_SESSION";
export const SET_PONG = "@SOCKET/LIVECHAT/SET_PONG";
export const SET_PROJECT_SETTINGS = "@SOCKET/LIVECHAT/SET_PROJECT_SETTINGS";
export const SET_LIVECHAT_OPTIONS = "@SOCKET/LIVECHAT/SET_LIVECHAT_OPTIONS";
export const SET_INTERACTION_STATUS = "@SOCKET/LIVECHAT/SET_INTERACTION_STATUS";
export const SET_ONLINE_STATUS = "@SOCKET/LIVECHAT/SET_ONLINE_STATUS";
export const SET_VIEW_STATUS = "@SOCKET/LIVECHAT/SET_VIEW_STATUS";
export const SET_CONNECTION_OPTIONS = "@SOCKET/LIVECHAT/SET_CONNECTION_OPTIONS";
export const SET_OTHER_SESSIONS_INFO = "@SOCKET/LIVECHAT/SET_OTHER_SESSIONS_INFO";
export const SET_LIVECHAT_LOADING_STATUS = "@SOCKET/LIVECHAT/SET_LIVECHAT_LOADING_STATUS";
export const SET_SESSION_COUNT_DELTA = "@SOCKET/LIVECHAT/SET_SESSION_COUNT_DELTA";
export const UPDATE_INTERACTION_STATUS = "@SOCKET/LIVECHAT/UPDATE_INTERACTION_STATUS";

export const SET_SESSION_LIST = "@SOCKET/LIVECHAT/SET_SESSION_LIST";
export const ADD_SESSION = "@SOCKET/LIVECHAT/ADD_SESSION";

export const APPEND_MESSAGE_TO_SESSION = "@SOCKET/LIVECHAT/APPEND_MESSAGE_TO_SESSION";
export const UPDATE_SESSION_STATUS = "@SOCKET/LIVECHAT/UPDATE_SESSION_STATUS";
export const UPDATE_SESSION_LABEL = "@SOCKET/LIVECHAT/UPDATE_SESSION_LABEL";
