import { put, select } from "redux-saga/effects";

import { selectCurrentProject } from "~store/user/selectors";

import { setLivechatOptions, wsSendSubscribeLevel } from "../actions";
import { selectLivechatOptions } from "../selectors";

export const broadcastActionTypes = {
  syncLivechatOptions: "syncLivechatOptions",
};

export default function* broadcastActionsHandler({ payload }) {
  switch (payload.type) {
    case broadcastActionTypes.syncLivechatOptions:
      const newLivechatOptions = payload?.payload;
      if (newLivechatOptions) {
        const currentLivechatOptions = yield select(selectLivechatOptions);
        yield put(setLivechatOptions(newLivechatOptions));
        const currentProject = yield select(selectCurrentProject);
        const filterArr = newLivechatOptions?.filters?.[currentProject?.id] || [];
        const queryObj = filterArr.reduce((acc, item) => {
          if (item.type === "text") {
            acc[item.key] = item.value[0];
          } else {
            acc[item.key] = item.value;
          }
          return acc;
        }, {});

        const currentProjectsFilter = currentLivechatOptions?.filters?.[currentProject?.id] || [];
        const newProjectsFilter = newLivechatOptions?.filters?.[currentProject?.id] || [];

        //detect if all filters are same
        const isFilterChanged =
          currentProjectsFilter.length !== newProjectsFilter.length ||
          currentProjectsFilter.some((item, index) => {
            const newItem = newProjectsFilter[index];
            if (
              item.key !== newItem.key ||
              item.lookupKey !== newItem.lookupKey ||
              item.value?.length !== newItem.value?.length
            ) {
              return true;
            }
            return item.value.some((value, valueIndex) => value !== newItem.value[valueIndex]);
          });

        if (isFilterChanged) {
          yield put(wsSendSubscribeLevel("all", queryObj));
        }
      }
      break;
    default:
  }
}
