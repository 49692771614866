import ConstantHelper from "~helpers/ConstantHelper";
import IFrameHelper from "~helpers/IFrameHelper";
import SanitizeHelper from "~helpers/SanitizeHelper";

export const [popupPosition, popupPositionOptions, popupPositionMap] = ConstantHelper.typify({
  bottomRight: "Bottom Right",
  bottomLeft: "Bottom Left",
  //   topLeft: "Top Left",
  //   topRight: "Top Right",
});

export const [popupFooterMode, popupFooterModeOptions, popupFooterModeMap] = ConstantHelper.typify({
  hidden: "Hidden",
  visible: "Visible",
});

export const [popupMinimizeExistingChat, popupMinimizeExistingChatOptions, popupMinimizeExistingChatMap] =
  ConstantHelper.typify({
    maximized: "Show maximized",
    minimized: "Minimize in existing chat",
  });

export const [popupAvatarVisibility, popupAvatarVisibilityOptions, popupAvatarVisibilityMap] = ConstantHelper.typify({
  hidden: "Hidden",
  visible: "Visible",
});

export const [popupSpeechRecognition, popupSpeechRecognitionOptions, popupSpeechRecognitionMap] = ConstantHelper.typify(
  {
    disabled: "Disabled",
    enabled: "Enabled",
  }
);

export const [popupTriggerType, popupTriggerTypeOptions, popupTriggerTypeMap] = ConstantHelper.typify({
  instant: "Instant visible after page load",
  delayed: "Delay the popup specified time after page load",
  scroll: "Visible after scrolling down by specified pixel",
});

export const popupIconSizeDefault = 62;
export const popupIconMarginDefault = 25;
export const popupChatHeightDefault = 650;
export const popupLottieOffsetXDefault = 0;
export const popupLottieOffsetYDefault = 0;

export const [popupIconType, popupIconTypeOptions, popupIconTypeMap] = ConstantHelper.typify({
  default: "Default",
  icon: "Icon",
  lottie: "Lottie",
});

export const popupSettingsMapper = (settings = {}) => {
  const {
    popup_button_type,
    popup_header_icon_type,
    popup_bot_icon_type,
    popup_style_info,
    chatbot_info,
    system_chatbot_info,
    ...rest
  } = settings;

  const styleInfo = popup_style_info || {};
  const chatbotInfo = chatbot_info || {};
  const systemChatbotInfo = system_chatbot_info || {};
  const mapped = {
    ...rest,
    popup_style_info: {
      ...styleInfo,
      popup_icon_type:
        styleInfo.popup_icon_type ?? (popup_button_type === "IC" ? popupIconType.icon : popupIconType.default),
      popup_header_icon_type:
        styleInfo.popup_header_icon_type ??
        (popup_header_icon_type === "IC" ? popupIconType.icon : popupIconType.default),
      popup_bot_icon_type:
        styleInfo.popup_bot_icon_type ?? (popup_bot_icon_type === "IC" ? popupIconType.icon : popupIconType.default),

      popup_position: styleInfo.popup_position ?? popupPosition.bottomRight,
      popup_icon_size: styleInfo.popup_icon_size ?? popupIconSizeDefault,

      /** @deprecated Use popup_margin_x and popup_margin_y instead and remove popup_margin on save */
      popup_margin: styleInfo.popup_margin ?? popupIconMarginDefault,
      popup_margin_x: styleInfo.popup_margin_x ?? popupIconMarginDefault,
      popup_margin_y: styleInfo.popup_margin_y ?? popupIconMarginDefault,
      chat_height: styleInfo.chat_height ?? popupChatHeightDefault,
      lottie_width: styleInfo.lottie_width ?? popupIconSizeDefault,
      lottie_height: styleInfo.lottie_height ?? popupIconSizeDefault,
      lottie_offset_x: styleInfo.lottie_offset_x ?? popupLottieOffsetXDefault,
      lottie_offset_y: styleInfo.lottie_offset_y ?? popupLottieOffsetYDefault,
      lottie_loop_delay: styleInfo.lottie_loop_delay ?? 0,
      lottie_loop_speed: styleInfo.lottie_loop_speed ?? 1,
      popup_ballon_timeout: styleInfo.popup_ballon_timeout ?? 0,
      popup_trigger_type: styleInfo.popup_trigger_type ?? popupTriggerType.instant,
      popup_trigger_type_payload: styleInfo.popup_trigger_type_payload ?? 0,
    },
    chatbot_info: {
      ...chatbotInfo,
      avatar_visibility: chatbotInfo.avatar_visibility ?? popupAvatarVisibility.visible,
      footer_mode: chatbotInfo.footer_mode ?? popupFooterMode.visible,
      speech_recognition_mode: chatbotInfo.speech_recognition_mode ?? popupSpeechRecognition.enabled,
      speech_recognition_auto_send: chatbotInfo.speech_recognition_auto_send ?? false,
      minimize_existing_Chat: chatbotInfo.minimize_existing_Chat ?? popupMinimizeExistingChat.maximized,
    },
    system_chatbot_info: {
      ...systemChatbotInfo,
      enable_footer_customization: systemChatbotInfo.enable_footer_customization ?? false,
    },
  };
  if (IFrameHelper.isWebChatMode()) {
    const stylePrefix = "style__";
    const urlParams = new URLSearchParams(window.location.search);

    const styleParamsEntries = [...urlParams.entries()].filter(([key, value]) => key.startsWith(stylePrefix) && value);
    const rootStyleKeyMap = [["popup_primary_color", "primary_color"]];
    const newStyleObj = {};
    const newStyleRootObj = {};
    for (const [keyRaw, valueRaw] of styleParamsEntries) {
      const key = SanitizeHelper.safeText(keyRaw);
      const value = SanitizeHelper.safeText(valueRaw);
      const styleKey = key.replace(stylePrefix, "");
      const isNumber = !isNaN(value);

      const styleValue = isNumber ? Number(value) : value;
      if (rootStyleKeyMap.some(([_originalKey, paramKey]) => paramKey === styleKey)) {
        const originalKey = rootStyleKeyMap.find(([_originalKey, paramKey]) => paramKey === styleKey)[0];
        newStyleRootObj[originalKey] = styleValue;
      } else {
        newStyleObj[styleKey] = styleValue;
      }
    }
    Object.assign(mapped.popup_style_info, newStyleObj);
    Object.assign(mapped, newStyleRootObj);
  }

  return mapped;
};
