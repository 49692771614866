import momentDurationFormatSetup from "moment-duration-format";
import moment from "moment-timezone";

momentDurationFormatSetup(moment);

const longDateFormat = {
  LT: "HH:mm",
  LTS: "HH:mm:ss",
  L: "DD/MM/YYYY",
  LL: "D MMMM YYYY",
  LLL: "D MMMM YYYY HH:mm",
  LLLL: "dddd, D MMMM YYYY HH:mm",
  l: "DD/MM/YYYY",
  ll: "D MMMM YYYY",
  lll: "D MMMM YYYY HH:mm",
  llll: "ddd, D MMMM YYYY HH:mm",
};
moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",

    s: "%ds",
    ss: "%ds",
    m: "%dm",
    mm: "%dm",
    h: "%dh",
    hh: "%dh",
    d: "%dd",
    dd: "%dd",
    // M: "%dmo",
    // MM: "%dmo",
    // y: "%dy",
    // yy: "%dy",
  },
  longDateFormat: longDateFormat,
});

moment.updateLocale("tr", {
  relativeTime: {
    future: "%s sonra",
    past: "%s önce",
    s: "%dsn",
    ss: "%dsn",
    m: "%ddk",
    mm: "%ddk",
    h: "%ds",
    hh: "%ds",
    d: "%dg",
    dd: "%dg",
    // w: "%dh",
    // ww: "%dh",
    // M: "%da",
    // MM: "%da",
    // y: "%dy",
    // yy: "%dy",
  },
  longDateFormat: longDateFormat,
});

export default class DateHelper {
  static formatInput = "YYYY-MM-DDTHH:mm:ss.SSSSSSZZ"; //31
  static formatInputSpace = "YYYY-MM-DD HH:mm:ss.SSSSSS ZZ"; //32
  static formatInput2 = "YYYY-MM-DDTHH:mm:ssZZ"; //25
  static formatInput3 = "DD/MM/YYYY, HH:mm:ss"; //20
  static formatHHmm = "HH:mm";
  static formatWithoutOffset = "YYYY-MM-DDTHH:mm:ss.SSSSSS";

  static userTimeFormat = "HH:mm";
  static userDateFormat = "DD/MM/YYYY";

  static getTimeFormat() {
    return this.userTimeFormat;
  }
  static getDateTimeFormat() {
    return `${this.userDateFormat} ${this.userTimeFormat}`;
  }
  static getDateFormat() {
    return this.userDateFormat;
  }

  static IsServerFormat(date) {
    if (typeof date === "string") {
      if (date.length === 25) {
        return this.formatInput2;
      } else if (date.length === 31) {
        return this.formatInput;
      } else if (date.length === 32) {
        return this.formatInputSpace;
      }
    }
    return false;
  }
  static _getFormat(date) {
    if (typeof date === "string") {
      if (date.length === 25) {
        return this.formatInput2;
      } else if (date.length === 31) {
        return this.formatInput;
      } else if (date.length === 32) {
        return this.formatInputSpace;
      } else if (date.length === 20) {
        return this.formatInput3;
      }
    }
    return this.formatInput;
  }
  static getLocale() {
    return moment.locale();
  }
  static get moment() {
    return moment;
  }
  /**
   * @param {string | moment.Moment} date - Date string or moment object
   * @param {string} format - Date format
   * @param {boolean} strict - Strict mode
   * @returns {moment.Moment} - Moment object
   */
  static getDateTime(date, format = "", strict = false) {
    if (!date) return moment().utc();
    let formatText;
    if (format === "ISO") {
      formatText = this.formatInput;
    } else if (format === "ISO_SPACE") {
      formatText = this.formatInputSpace;
    } else {
      formatText = format;
    }
    return moment.utc(date, formatText || this._getFormat(date), strict);
  }
  /**
   * @param {string | moment.Moment} date - Date string or moment object
   * @param {string} format - Date format
   * @param {boolean} strict - Strict mode
   * @returns {moment.Moment} - Moment object
   */
  static getDateTimeLocal(date, format = "", strict = false) {
    if (!date) return moment().local();
    let formatText;
    if (format === "ISO") {
      formatText = this.formatInput;
    } else if (format === "ISO_SPACE") {
      formatText = this.formatInputSpace;
    } else {
      formatText = format;
    }
    return moment.utc(date, formatText || this._getFormat(date), strict).local();
  }

  static getTime(date, format) {
    return this.getDateTime(date).format(format ?? this.formatHHmm);
  }
  static getTimeLocal(date, format) {
    return this.getDateTimeLocal(date).format(format ?? this.formatHHmm);
  }

  /**
   * @param {any} date
   * @param {"ISO" | "ISO_SPACE"} format
   * @returns
   */
  static format(date, format = "ISO") {
    let formatText;
    if (format === "ISO") {
      formatText = this.formatInput;
    } else if (format === "ISO_SPACE") {
      formatText = this.formatInputSpace;
    } else if (format === "NO_OFFSET") {
      formatText = this.formatWithoutOffset;
    } else {
      formatText = format;
    }
    let formattedDate;
    if (moment.isMoment(date)) {
      formattedDate = date.format(formatText);
    } else {
      formattedDate = this.getDateTime(date).format(formatText);
    }
    if (format === "NO_OFFSET") {
      //Equivalent to ZZ
      formattedDate = `${formattedDate}+0000`;
    }
    return formattedDate;
  }

  /**
   * @param {number} value - Value
   * @param {moment.unitOfTime.Base} type Type of time unit (Default: "seconds")
   * @param {string | "auto"} format Format of time unit (Default: "h [hrs] m [min] s [sec]")
   * @returns {string} - Formatted time
   */
  static formatMoment(value, type = "minutes", format = "h [hrs] m [min] s [sec]") {
    const duration = moment.duration(value, type);

    let formattedText = "";
    if (format === "auto") {
      if (duration.months() > 0) {
        formattedText += `${duration.months()} M ${duration.days()} day`;
      } else if (duration.days() > 0) {
        formattedText += `${duration.days()} day ${duration.hours()} hrs`;
      } else if (duration.hours() > 0) {
        formattedText += `${duration.hours()} hrs ${duration.minutes()} min`;
      } else if (duration.minutes() > 0) {
        formattedText += `${duration.minutes()} min ${duration.seconds()} sec`;
      } else {
        formattedText += `${duration.seconds()} sec`;
      }
    } else {
      formattedText = duration.format(format);
    }
    return formattedText;
  }

  static getTimezone() {
    return moment.tz.guess();
  }
}
